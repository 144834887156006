import React from 'react'

import Image from './image'
// import Link from './link'
import GetQuote from './getquote'

const JourneyOmnidya = (props) => {
  const { gutter } = props
  return(
    <div className={`container-xxl ${gutter && "py-5"}`}>
      <div className="blue-bg rounded-xxlg text-center position-relative">
        <div className="globe">
          <Image src="group-7.svg" />
        </div>
        <div className="row justify-content-center">
          <div className="col col-lg-8 text-center text-white">
            <div className="header-slogan mb-3">
              Ready to start your journey with Omnidya?
            </div>
            <div className="mb-5">Going places? Take us with you</div>
            {/* <Link className="btn btn-blue">Sign Up and Save</Link> */}
            <GetQuote btnLabel="Sign Up and Save" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default JourneyOmnidya