import React from 'react'

import Layout from '../component/layout'
import Link from '../component/link'
import SubTitle from '../component/typography/subtitle'
// import Testimonial from '../component/testimonial'
import JourneyOmnidya from '../component/journeyOmnidya'

const ThankYou = () => {
  return(
    <Layout page="blog-header-bg">
      <div className="container-xxl">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-8 text-center">
            <div className="py-5">
              <div className="header-slogan pb-3">
                Thank you for subscription
              </div>
              <p>hat is Lorem Ipsum is simply dummy text of the printing and typesetting industry Great talent can be found anywhere in the world. We make it easy for you to employ.</p>
            </div>
            <SubTitle title="You can go back or contact us for further information" className="mb-3" />
            
            <Link to="/" className="btn btn-gradient">
              Go Back
            </Link>
            <Link to="/contact" className="btn">
              Contact
            </Link>
          </div>
        </div>
      </div>
      <JourneyOmnidya gutter />
    </Layout>
  )
}

export default ThankYou